import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Image from "./image"

interface IProps {
  service: Service
}

interface Service {
  title: string
  image: string
  description: string
}

const ServiceCard = ({ service, ...props }: IProps) => {
  return (
    <Card
      style={{
        borderRadius: "1rem",
        //minHeight: "20rem",
        boxShadow: "0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 10%)",
      }}
    >
      <CardContent style={{ textAlign: "start", minHeight: 200 }}>
        <div style={{ width: 80, padding: 10 }}>
          <Image alt={service.title} filename={service.image} />
        </div>
        <Typography variant="h6" color="inherit">
          {service.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {service.description}
        </Typography>
      </CardContent>
    </Card>
  )
}
export default ServiceCard
