import * as React from "react";
import { Helmet } from "react-helmet";
import favicon from "../images/favicon.ico";
import image from "../images/Recuperet.png";

export default function HelmetComponent() {
  const title = "Recuperet Health";
  const description =
    "Recuperet helps patients with their postoperative recovery by connecting patients with their doctors through a technology platform";
  const url = "https://recuperet.io";
  const user = "@AroraGary";
  const imageUrl = url + image;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="icon" href={favicon} />
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />
      <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      <meta name="image" content={imageUrl} />
      <meta property="og:url" content={url} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={user} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  );
}
