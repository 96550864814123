import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Image from "./image"
import Hidden from "@material-ui/core/Hidden"
import logo from "../images/Recuperet_Logo.png"

const Hero = () => {
  return (
    <Grid container spacing={3} style={{ minHeight: 500 }}>
      <Grid
        item={true}
        xs={12}
        sm={6}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ paddingLeft: 40, paddingRight: 40 }}
      >
        <Typography variant="h4" color="inherit" style={{ marginBottom: 20 }}>
          Recuperet Health
        </Typography>

        <Typography variant="subtitle1" style={{ marginBottom: 30 }}>
          Recuperet helps patients with their postoperative recovery by
          connecting patients with their doctors through a technology platform
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => (window.location.href = "mailto:hello@recuperet.io?subject=Connecting from Your Site")}
        >
          Get in Touch
        </Button>
      </Grid>
      <Grid
        item={true}
        sm={6}
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ padding: 10 }}
      >
        <div style={{ width: "100%" }}>
          <Image
            alt="Recuperet: Simplified Patient Monitoring"
            filename="hero-image.png"
          />
        </div>
      </Grid>
    </Grid>
  )
}
export default Hero
