import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ServiceCard from "./service-card"
import Bar from "./bar"

const Services = () => {
  let services = [
    {
      id: 1,
      image: "search-doctor-icon.png",
      title: "Wearables",
      description:
        "Relevant wearables, tailored to patient's recovery regimen to monitor progress",
    },
    {
      id: 2,
      image: "online-pharmacy-icon.png",
      title: "AI Algorithms",
      description:
        "Proprietary AI algorithms to guide patient recovery and proactively prevent negative patient outcomes",
    },
    {
      id: 3,
      image: "consultation-icon.png",
      title: "Recovery Champion",
      description:
        "Patient-selected recovery champion (e.g., a family member) to be part of the recovery journey",
    },
    {
      id: 4,
      image: "details-info-icon.png",
      title: "Two-way Messaging",
      description:
        "Two-way secure messaging between patient, provider, healthcare team, and recovery champion to quickly raise questions/concerns",
    },
    {
      id: 5,
      image: "emergency-care-icon.png",
      title: "Dashboard",
      description:
        "Patient & Provider dashboards to check-in and monitor daily progress on recovery efforts",
    },
    {
      id: 6,
      image: "tracking-icon.png",
      title: "Community Support",
      description: "Community forums with verified patients to anonymously ask questions and discuss their recovery",
    },
  ]

  return (
    <Grid
      container
      spacing={3}
      style={{
        textAlign: "center",
        minHeight: 200,
        padding: 10,
      }}
    >
      <Grid
        item
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Typography variant="h5">What makes Recuperet Unique</Typography>
        <Bar />
       
      </Grid>

      {services.map(service => (
        <Grid item xs={12} sm={4} key={service.id}>
          <ServiceCard service={service} />
        </Grid>
      ))}
      {/* 
      <Grid item xs={12}>
        <Button variant="outlined" color="primary" size="large">
          Learn More
        </Button>
      </Grid>*/}
    </Grid>
  )
}
export default Services
